//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import html2canvas from "html2canvas";
export default {
  name: "",
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      url: '',
      top: '',
      isShow: false,
      toast: '',
      isToast: true
    };
  },
  created: function created() {
    var top = document.documentElement.scrollTop || document.body.scrollTop;
    this.top = top;
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
    this.toast = this.$toast.loading({
      message: '海报生成中',
      forbidClick: true
    });
    console.log(this.toast);
  },
  methods: {
    close: function close() {
      this.$emit("click");
    },
    createPoster: function createPoster() {
      var _this = this;

      setTimeout(function () {
        new html2canvas(document.querySelector(".inside-1-item"), {
          allowTaint: false,
          useCORS: true,
          backgroundColor: null,
          // scale: 2,
          dpi: 300
        }).then(function (canvas) {
          _this.url = canvas.toDataURL("image/png");

          if (_this.isToast) {
            _this.isToast = false;

            _this.toast.clear();

            console.log(_this.toast);
          }
        });
      }, 300);
    },
    copyText: function copyText(text) {
      var copyDom = document.createElement("div");
      copyDom.innerText = text;
      copyDom.style.position = "absolute";
      copyDom.style.top = "0px";
      copyDom.style.right = "0px";
      copyDom.style.zIndex = "-10";
      copyDom.style.opacity = "0";
      document.body.appendChild(copyDom); // 创建选中范围

      var range = document.createRange();
      0;
      range.selectNode(copyDom); // 移除剪切板中内容

      window.getSelection().removeAllRanges(); // 添加新的内容到剪切板

      window.getSelection().addRange(range);
      document.execCommand("copy");
      copyDom.parentNode.removeChild(copyDom);
      this.$toast('复制成功');
    }
  },
  beforeDestroy: function beforeDestroy() {
    document.documentElement.scrollTop = this.top;
    document.body.scrollTop = this.top;
  }
};