<template>
    <div class="invita">
        <div class="box">
            <div class="title flex">
                邀请有礼
            </div>
            <div class="info" v-html="$$(data.poster,0).invite_text">

            </div>
            <div class="btn" @click="openPoster">点击生成我的邀请海报和文案</div>
        </div>

        <div class="box">
            <div class="title flex">
                我的奖励
            </div>
            <div class="money flex-between">
                <div class="left">
                    <p>已获得奖励：<strong>{{data.allcash}}元</strong></p>
                    <p>已提现奖励：<strong>{{data.cashed}}元</strong></p>
                    <p>可提现奖励：<strong :style="{color:data.cancash != 0 ? '':'#999999'}">{{data.cancash}}元</strong></p>
                </div>
                <div :class="{'right':true, 'active':data.cancash != 0}" @click="w()">
                    申请提现
                </div>
            </div>

            <div class="info" style="color: #666666;font-size: 14px">
                <p style="color: #333333;font-size: 14px;margin-bottom: 5px">奖励发放规则：</p>
                <p class="flex" style="align-items: end"><span>1.</span> 被邀请用户成功下单，奖励将直接在“已获得奖励”中</p>
                <p class="flex" style="align-items: end"><span>2.</span>用户成功下单且7个工作日未退费，奖励将可提现</p>
                <p class="flex" style="align-items: end"><span>3. </span>申请现提后，填写支付宝账号，奖励将在2个工作日内进行审核通过并发送给您</p>
            </div>
        </div>

        <Poster v-if="show" @click="show = false" :data="data"/>
    </div>
</template>

<script>
    import Poster  from "../../components/Poster";
    export default {
        name: "",
        components:{
            Poster
        },
        data(){
            return{
                show:false,
                data:{}
            }
        },
        created() {
            this.getData()
        },
        methods:{
            getData(){
                let toast = this.$toast.loading({
                    message: '加载中',
                    forbidClick: true,
                    overlay:true
                });
                this.$request.get("get_invite_info").then(res => {
                    if(res.code === 1){
                        this.data = res.data
                        toast.clear()
                        console.log(res)
                    }
                })
            },
            openPoster(){
                if(this.data.poster?.length < 1){
                    Toast("暂无海报")
                }else {
                    this.show = true
                }

            },
            w(){
                if(this.data.cancash != 0){
                    this.$router.push({
                        path:'/my/cashout'
                    })
                }
            }
        }
    }
</script>

<style scoped lang="less">
    .invita{
        width:100%;
        min-height: 100vh;
        background-color:#F3F3F4;
        background-image: url("https://khome2.tuzuu.com/vita/ahome_2.png");
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: top center;
        padding: 25px 10px;

        box-sizing: border-box;
    }

    .box{
        width:100%;
        padding: 20px 15px;
        box-sizing: border-box;
        background-color: white;
        border-radius: 10px;
        margin-bottom: 15px;
        .title{
            font-size:18px;
            font-weight: 700;
            color: #292C2F;
            &:before{
                content:'';
                display:block;
                width: 5px;
                height: 20px;
                border-radius: 11px;
                background-color: #6B00AF;
                margin-right: 5px;
            }
        }
        .info{
            margin-top: 10px;
            color: #666666;
            font-size: 14px;
            letter-spacing: 0.5px;
            line-height: 23px;

        }
        .btn{
            font-weight: 700;
            color: white;
            font-size: 15px;
            background-color: #6B00AF;
            border-radius: 20px;
            padding: 10px 0px;
            text-align: center;
            width: 80%;
            margin: 0 auto;
            margin-top: 30px;

        }
    }

    .money{
        margin-top: 15px;
        align-items: flex-end;
        padding-bottom: 20px;
        border-bottom: 1px solid #CFCFCF;

        .left{
            color: #666666;
            font-size: 14px;
            line-height: 25px;
            strong{
                color: #232323;
                font-size: 16px;
                margin-left: 5px;
            }
        }
        .right{
            width: fit-content;
            color: #C6C6C6;
            border: 1px solid #979797;
            border-radius: 20px;
            padding: 7px 14px;
            font-size: 14px;
            //margin-right: 8px;
        }
        .active{
            color: #6B00AF;
            border: 1px solid #6B00AF;
        }
    }
</style>